@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.InlineImages';

#{$name} {

    &-title {
        font-size: .875rem;
        display: flex;

        @include media-breakpoint-up(md) {
            border-right: 1px solid $gray-400;
        }
    }

    &-item img { // For some reason experience editor does not display svg images when added until save.
        min-width: 60px;
        min-height: 30px;
    }

    &-image {
        min-width: 60px;
        min-height: 30px;
    }
}
